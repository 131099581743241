// Import polyfill from node_modules
// import 'core-js/modules/es6.set.js'
// import 'core-js/modules/es6.array.find-index.js'

(function() {
	var win = $(window),
		ww = win.innerWidth(),
		wh = win.innerHeight(),
		window_width = win.width(),
		ws = win.scrollTop();
	var resizeWindow = function(){
		ww = win.innerWidth();
		wh = win.innerHeight();
	}
	$(window)
		.on('load', function () {
			$('.in-kv').addClass('hover');

			// 1280x563
			// http://13.57.24.231/login
			// var window_w = $(window).width();
			// var window_h = $(window).height();
			// alert("螢幕寬度：" + window_w + "，螢幕高度：" + window_h);
			
			var EhpSlider = new Swiper('.swiper-container.ehpitem', {
				// autoplay:{delay:8,autoplayDisableOninteraction:false},
				loop: !1,
				autoplayDisableOninteraction:false,
				spaceBetween: 30,
				slidesPerView: 3,
				autoHeight: !0,
				watchSlidesVisibility: true,//防止不可点击
				touchRatio: 0.08,
				watchSlidesProgress: true,
				simulateTouch:!0,
				preventClicks:!0,
				followFinger:!1,
				resistance:!1,
				// navigation:{nextEl:".swiper-container.threesliderbox .swiper-arrow.next",prevEl:".swiper-container.threesliderbox .swiper-arrow.prev"},
				loopAdditionalSlides:1,
				// pagination:{el:".swiper-container.threesliderbox .swiper-pagination",clickable:!0},
				slideToClickedSlide: !0,
				breakpoints: {
					0: {
						slidesPerView: 1,
						spaceBetween: 0,
					},
					374: {
						slidesPerView: 1,
						spaceBetween: 10,
					},
					641: {
						slidesPerView: 2,
						spaceBetween: 10,
					},
					1024: {
						slidesPerView: 2,
					},
					1201: {
						slidesPerView: 3,
					}
				},
			});
			// var PnrSlider = new Swiper('.swiper-container.pnritem', {
			// 	// autoplay:{delay:8,autoplayDisableOninteraction:false},
			// 	effect: "fade",
			// 	speed: 1200,
			// 	// loop: true,
			// 	autoplayDisableOninteraction:false,
			// 	slidesPerView: 1,
			// 	autoHeight: !0,
			// 	watchSlidesVisibility: true,//防止不可点击
			// 	touchRatio: 0.08,
			// 	watchSlidesProgress: true,
			// 	simulateTouch:!0,
			// 	preventClicks:!0,
			// 	followFinger:!1,
			// 	resistance:!1,
			// 	// navigation:{nextEl:".swiper-container.threesliderbox .swiper-arrow.next",prevEl:".swiper-container.threesliderbox .swiper-arrow.prev"},
			// 	loopAdditionalSlides:1,
			// 	pagination:{el:".swiper-container.pnritem .swiper-pagination",clickable:!0},
			// 	slideToClickedSlide: !0
			// });

			
			var KVIP = document.getElementById('IProbe');
			var ENJOYIP = document.getElementById('IProbe2');
			var ProIP = document.getElementById('ProIp');
			var IndIP = document.getElementById('IndIp');
			var KVIPMove = new Parallax(KVIP,{
				invertX:!0,invertY:!0,frictionX:.06,frictionY:.06
			});
			var KVIPMove = new Parallax(ENJOYIP,{
				invertX:!0,invertY:!0,frictionX:.06,frictionY:.06
			});
			var ProIPMove = new Parallax(ProIP,{
				invertX:!0,invertY:!0,frictionX:.06,frictionY:.06
			});
			var IndIPMove = new Parallax(IndIP,{
				invertX:!0,invertY:!0,frictionX:.06,frictionY:.06
			});

			ScrollToEarth();

		})
		.on('scroll', function () {
			setTimeout(() => {
				IndexScroll();
				ScrollToEarth();
				// PnrScroll();
			}, 0);
		})
		.on('resize', function () {
			IndexScroll();
			ScrollToEarth();
		});
	// -----------------------------------
	// 頁面滾動時的動畫判斷
	// -----------------------------------
	function IndexScroll(){
		// 頁面滾動時的動畫判斷
		var $animation_elements = $('.js-animate');
		var window_height = win.height();
		var window_top_position = win.scrollTop();
		var window_bottom_position = (window_top_position + window_height);

		$.each($animation_elements, function() {
			var $element = $(this);
			var element_height = $element.outerHeight();
			var element_top_position = $element.offset().top;
			var element_bottom_position = (element_top_position + element_height + (element_height / 3));

			//check to see if this current container is within viewport
			if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
				$element.addClass('hover');
			}else{
				$element.removeClass('hover');
			}
		});
	}
	function ScrollToEarth(){
		var kv_h = $('.in-kv').height();
		// var in_h = $('.in-do').height();
		var why_h = $('.in-why').height();
		// var ex_h = $('.in-ex').height();
		var window_top_position = win.scrollTop();

		if((window_top_position >= kv_h / 1.5) && (window_top_position <= why_h + why_h / 4.5)){
			$('.in-earth').addClass('is-active');
			$('.in-bg').addClass('is-active');
			// $('.whytitle-ctr').addClass('fixed');
		}else if ((window_top_position <= kv_h) && window_top_position == 0){
			$('.in-earth').removeClass('is-active');
			$('.in-bg').removeClass('is-active');
			// $('.whytitle-ctr').removeClass('fixed');
		}else{
			$('.in-earth').removeClass('is-active');
			// $('.whytitle-ctr').removeClass('fixed');
		}
		// console.log("kv=",kv_h,"WHY=",why_h,"sCROLLING=",window_top_position);

		
		if(window_top_position >= kv_h){
			$('.in-bg').addClass('is-active');
		}
	}
	function PnrScroll(){
		// 頁面滾動時的動畫判斷
		var kvHeight = $('.in-kv').height();
		var whyTitleHeight = $('.whytitle').height();
		var whyHeight = $('.in-why').height();
		var doHeight = $('.in-do').height();
		var ehpHeight = $('.in-ehp').height();
		var pnrHeight = $('.in-pnr').height();
		var pnrHeightCont = $(window).height();

		var PnrFornHheight = kvHeight + whyTitleHeight + whyHeight + doHeight + ehpHeight;
		// var PnrFornHheight = kvHeight + whyTitleHeight + whyHeight + ehpHeight;

		var window_top_position = win.scrollTop();

		if(window_top_position > PnrFornHheight + (pnrHeightCont/4) && window_top_position < PnrFornHheight + pnrHeightCont + (pnrHeightCont/4)){
			$('.pnrbox').removeClass('is-active');
			$('.pb01').addClass('is-active');
			$('.pnr-bgtextslider , .pnr-bgitembox , .pnr-bgcolor').addClass('is-active');
		}else if(window_top_position > PnrFornHheight + pnrHeightCont + (pnrHeightCont/4) && window_top_position < PnrFornHheight + pnrHeightCont + pnrHeightCont + (pnrHeightCont/4)){
			$('.pnrbox').removeClass('is-active');
			$('.pb02').addClass('is-active');
			$('.pnr-bgtextslider , .pnr-bgitembox , .pnr-bgcolor').addClass('is-active');
		}else if(window_top_position > PnrFornHheight + pnrHeightCont + pnrHeightCont + (pnrHeightCont/4) && window_top_position < PnrFornHheight + pnrHeightCont + pnrHeightCont + pnrHeightCont + (pnrHeightCont/2)){
			$('.pnrbox').removeClass('is-active');
			$('.pb03').addClass('is-active');
			$('.pnr-bgtextslider , .pnr-bgitembox , .pnr-bgcolor').addClass('is-active');
		}else{
			$('.pnrbox').removeClass('is-active');
			$('.pnr-bgtextslider , .pnr-bgitembox , .pnr-bgcolor').removeClass('is-active');
		}

	}
	

	window.openLpgBanner = function() {
        var LpgBanner = new Foundation.Reveal($('#LpgBanner'));
        LpgBanner.open();
    }


})();



